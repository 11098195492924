import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {brand} from '../../settings';
import {SVGICONDEFAULT} from '../default/svgIconDefaultList';
import {SVGICONTHEME} from './svgIconThemeList';
import {ICONSACTIVITYMODULE} from './svgIconThemeActivitiyList';

@Injectable()
export class SvgIconList {

    iconsSvgDefaultCollection = SVGICONDEFAULT;
    iconsSvgThemeCollection = SVGICONTHEME;
    iconsActivitiesModule = ICONSACTIVITYMODULE;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        const iconList = this.mergeIconList([
            this.iconsSvgDefaultCollection,
            this.iconsSvgThemeCollection,
            this.iconsActivitiesModule
        ]);
        this.iconRegister( iconList, brand);
    }

    mergeIconList(iconLists: Object[]): object {
        const newIconList = {};

        iconLists.forEach(themeList => {
            Object.keys(themeList).map(k => {
                newIconList[k] = themeList[k];
            });
        });
        return newIconList;
    }


    iconRegister( iconObj: Object | Object[], brand: string ): void {
        let pathIconSvgUrl: string;

        for ( const key in iconObj ) {

            if ( typeof iconObj[key] === 'object' && iconObj[key].icon_path && iconObj[key].icon_name ) {
                pathIconSvgUrl = 'assets/' + brand + '/' + iconObj[key].icon_path + '/icons/' + iconObj[key].icon_name + '.svg';
            }else{
                pathIconSvgUrl = 'assets/' + brand + '/icons/' + iconObj[key] + '.svg';
            }

            this.matIconRegistry.addSvgIcon( key, this.domSanitizer.bypassSecurityTrustResourceUrl(pathIconSvgUrl) );

        }
    }

}
