import {ModuleIconInterface} from "../module-icon.interface";

export class SvgIcon {
    [key: string]: string | ModuleIconInterface
}

export const ICONSACTIVITYMODULE: SvgIcon = {
    'activity-edition-preview__audio': {
        icon_path: 'activities',
        icon_name: 'audio_waveform'
    },
    'activity-edition-preview__check': {
        icon_path: 'activities',
        icon_name: 'check'
    },
    'activity-edition-preview__close': {
        icon_path: 'activities',
        icon_name: 'close_fill_700'
    },
    'activity-edition-preview__false': {
        icon_path: 'activities',
        icon_name: 'block'
    },
    'activity-edition-preview__help': {
        icon_path: 'activities',
        icon_name: 'person_raised_hand'
    },
    'activity-edition-preview__true': {
        icon_path: 'activities',
        icon_name: 'download_done_rounded'
    },
    'activity-edition-preview__ttl': {
        icon_path: 'activities',
        icon_name: 'volume_up_fill_700'
    },
    'activity-edition-typology-icon__MULTI': {
        icon_path: 'activities',
        icon_name: 'multimedia'
    },
    'activity-edition-typology-icon__QCM': {
        icon_path: 'activities',
        icon_name: 'qcm'
    },
    'activity-edition-typology-icon__QCMU': {
        icon_path: 'activities',
        icon_name: 'qcu'
    },
    'activity-edition-typology-icon__ORDMAT': {
        icon_path: 'activities',
        icon_name: 'ordering'
    },
    'activity-edition-typology-icon__TXTMAT': {
        icon_path: 'activities',
        icon_name: 'matching'
    },
    'activity-edition-typology-icon__VF': {
        icon_path: 'activities',
        icon_name: 'true-false'
    },
    'activity-edition-typology-icon__CRT': {
        icon_path: 'activities',
        icon_name: 'short-answer'
    },
    'activity-edition-typology-icon__recording': {
        icon_path: 'activities',
        icon_name: 'voice-recorder'
    },
    'activity-edition-typology-icon__shortAnswerButLong': {
        icon_path: 'activities',
        icon_name: 'fill-in-blanks'
    },
    'activity-edition-form-icon__pairing': {
        icon_path: 'activities',
        icon_name: 'pairing'
    },
    'activity-edition-form-icon__up': {
        icon_path: 'activities',
        icon_name: 'move_up'
    },
    'activity-edition-form-icon__down': {
        icon_path: 'activities',
        icon_name: 'move_down'
    },
    'activity-edition-matching-form-icon__up': {
        icon_path: 'activities',
        icon_name: 'move_up'
    },
    'activity-edition-matching-form-icon__down': {
        icon_path: 'activities',
        icon_name: 'move_down'
    },
    'edition-preview__audio': {
        icon_path: 'activities',
        icon_name: 'equalizer_1--default'
    },
    'activity-sample__audio': {
        icon_path: 'activities',
        icon_name: 'graphic_equalizer'
    },
    'activity-sample__document': {
        icon_path: 'activities',
        icon_name: 'picture_as_pdf'
    },
    'activity-sample__image': {
        icon_path: 'activities',
        icon_name: 'panorama'
    },
    'activity-sample__image-search': {
        icon_path: 'activities',
        icon_name: 'image_search'
    },
    'activity-sample__keyboard': {
        icon_path: 'activities',
        icon_name: 'keyboard'
    },
    'activity-sample__matching': {
        icon_path: 'activities',
        icon_name: 'attach_file'
    },
    'activity-sample__microphone': {
        icon_path: 'activities',
        icon_name: 'auto_detect_voice'
    },
    'activity-sample__portal': {
        icon_path: 'activities',
        icon_name: 'captive_portal'
    },
    'activity-sample__text': {
        icon_path: 'activities',
        icon_name: 'format_size'
    },
    'activity-sample__tools': {
        icon_path: 'activities',
        icon_name: 'construction'
    },
    'activity-sample__undefined': {
        icon_path: 'activities',
        icon_name: 'graphic_equalizer'
    },
    'activity-sample__video': {
        icon_path: 'activities',
        icon_name: 'play'
    },
    'app-activity-search-engine-panel__selected-count-label__icon': {
        icon_path: 'activities',
        icon_name: 'check'
    },
    'app-activity-search-engine-panel__validate-selection-button__icon': {
        icon_path: 'activities',
        icon_name: 'download'
    },
    'image': {
        icon_path: 'activities',
        icon_name: 'panorama'
    },
    'audio': {
        icon_path: 'activities',
        icon_name: 'graphic_eq'
    },
    'flashcards': {
        icon_path: 'activities',
        icon_name: 'flashcards'
    },
    'thumb_up': {
        icon_path: 'activities',
        icon_name: 'thumb_up--outline'
    },
    'thumb_down': {
        icon_path: 'activities',
        icon_name: 'thumb_down--outline'
    },
    'neo__icon-first-time': {
        icon_path: 'activities',
        icon_name: 'bright_stars'
    },
    'neo__icon-already-practiced': {
        icon_path: 'activities',
        icon_name: 'plus'
    },
    'neo__icon-searching': {
        icon_path: 'activities',
        icon_name: 'search'
    },
    'lesson-meta-form__input_icon': {
        icon_path: 'activities',
        icon_name: 'search'
    },
    'move_right': {
        icon_path: 'activities',
        icon_name: 'move_right'
    },
    'move_left': {
        icon_path: 'activities',
        icon_name: 'move_left'
    },
    'undo': {
        icon_path: 'activities',
        icon_name: 'undo'
    },
    'redo': {
        icon_path: 'activities',
        icon_name: 'redo'
    },
    'editor-panel__button-add-icon': {
        icon_path: 'activities',
        icon_name: 'folder_open'
    },
    'editor-panel__button-create-icon': {
        icon_path: 'activities',
        icon_name: 'add_to_queue'
    },
    'sound_off': {
        icon_path: 'activities',
        icon_name: 'sound_off'
    },
    'sound_on': {
        icon_path: 'activities',
        icon_name: 'sound_on'
    },
    'order-matching__audio-1': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_1',
    },
    'order-matching__audio-2': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_2',
    },
    'order-matching__audio-3': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_3',
    },
    'order-matching__audio-4': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_4',
    },
    'qcm-qcu__audio-1': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_1',
    },
    'qcm-qcu__audio-2': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_2',
    },
    'qcm-qcu__audio-3': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_3',
    },
    'qcm-qcu__audio-4': {
        icon_path: 'activities',
        icon_name: 'speaker_equalizer_4',
    },
    'qcm__register__incorrect': {
        icon_path: 'activities',
        icon_name: 'false'
    },
    'qcm__register__not-correct': {
        icon_path: 'activities',
        icon_name: 'false'
    },
    'qcm__register__null': {
        icon_path: 'activities',
        icon_name: 'true'
    },
    'qcm__register__pristine': {
        icon_path: 'activities',
        icon_name: 'true'
    },
    'true': {
        icon_path: 'activities',
        icon_name: 'true'
    },
    'false': {
        icon_path: 'activities',
        icon_name: 'false'
    },
    'pretty-card__menu': {
        icon_path: 'activities',
        icon_name: 'more_horiz'
    },
    'voice-recorder__listen': {
        icon_path: 'activities',
        icon_name: 'volume_down'
    },
    'voice-recorder__listen-and-compare': {
        icon_path: 'activities',
        icon_name: 'headphones'
    },
    'voice-recorder__record': {
        icon_path: 'activities',
        icon_name: 'mic'
    },
    'voice-recorder__stop-recording': {
        icon_path: 'activities',
        icon_name: 'stop'
    },
}